import Dom from './_dom';

export default class Page {

	constructor() { }

	init() {
		const body = document.body;
		const main = document.querySelector('.main');
		const header = document.querySelector('.header');
		const nav = document.querySelector('[data-nav]');

		Dom.detect(body);

		this.body = body;
		this.main = main;
		this.header = header;
		this.nav = nav;
		this.isMobile = Dom.mobile;
		this.resizeTimeout = null;

		this.addListeners();
	}

	addListeners() {
		window.addEventListener('orientationchange', (e) => {
			this.checkDevice();
		});
		if (this.isMobile) {
			return;
		}
		window.addEventListener('resize', this.onResize.bind(this));
	}

	onResize() {
		this.disableTransitions();
	}

	disableTransitions() {
		clearTimeout(this.resizeTimeout);
		document.body.classList.add('_resizing');
		this.resizeTimeout = setTimeout(() => {
			document.body.classList.remove('_resizing');
		}, 500);
	};

	checkDevice(e) {
		if (!this.isMobile) {
			return;
		}
		const html = document.querySelector('html');
		const orientation = window.orientation & 2;
		switch (orientation) {
			case 0:
				html.classList.remove('landscape');
				html.classList.add('portrait');
				break;
			case 2:
				html.classList.remove('portrait');
				html.classList.add('landscape');
				break;
		}
	}
}
