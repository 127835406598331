export default class SwiperSettings {

  static params = {
    carousel: {
      spaceBetween: 20,
      loop: true,
      effect: "fade",
      allowTouchMove: false,
      autoplay: {
        delay: 4000,
      },
      speed: 1000
    },
  }

  static features = {
    carousel: {},
  }

  static getParams(key) {
    return Object.assign({}, this.params[key]);
  }

  static getFeatures(key) {
    return this.features[key];
  }

  static get(key) {
    return {
      params: this.getParams(key),
      features: this.getFeatures(key),
    }
  }
}
