import Swiper from 'swiper';
import { Autoplay, EffectFade } from 'swiper/modules'
import SwiperSettings from './swiper-settings';

import 'swiper/css';
import 'swiper/css/effect-fade';

export default class SwiperSlider {

  constructor(node) {
    this.node = node;
    this.id = this.node.dataset.swiperId;
    this.type = this.node.dataset.swiperSlider;
    this.params = SwiperSettings.getParams(this.type);
    this.features = SwiperSettings.getFeatures(this.type);
    this.init();
  }

  init() {
    //setting swiper plugins
    Swiper.use([Autoplay, EffectFade]);

    //swiper init
    this.swiper = new Swiper(this.node, this.params);
  }


  static init(context) {
    const node = context ? context : document;
    SwiperSlider.item = Array.from(
      node.querySelectorAll('[data-swiper-slider]')).filter(item => item.dataset.swiperSlider !== 'controls').map((node, index) => new SwiperSlider(node, index));
  }
}
