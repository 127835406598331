import { Page } from './shared';
import SwiperSlider from './swiper/swiper-slider';

//css
export default class App extends Page {

	constructor(el) {
		super(el);
		this.init();
		const swipers = SwiperSlider.init();
	}
}

window.onload = () => {
	const app = new App();
};

